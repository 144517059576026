import type { FC } from "react";
import asTemplateValue from "lib/asTemplateValue";
import React, { useCallback, useEffect, useMemo } from "react";
import { IconCreditCardPaymentBilling } from "src/constants/icons";
import translate from "src/lib/translate";
import ProfileCubit from "src/state/ProfileCubit/ProfileCubit";
import { useBloc } from "src/state/state";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import Data from "src/ui/components/Data/Data";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import Translate from "src/ui/components/Translate/Translate";

const BillingInformation: FC = () => {
  const [profile, { loadPaymentMethods, openEditBilling }] =
    useBloc(ProfileCubit);
  const [loading, setLoading] = React.useState(false);

  const defaultPaymentMethod = useMemo(() => {
    return profile.data.paymentMethods?.find((method) => method.default);
  }, [profile]);

  const openStripe = useCallback(() => {
    setLoading(true);
    void openEditBilling().then(() => {
      setLoading(false);
    });
  }, []);

  const handleWindowFocus = useCallback(() => {
    setLoading(true);
    void loadPaymentMethods().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    void loadPaymentMethods().then(() => {
      setLoading(false);
    });

    //   add event listener when window is focused
    window.addEventListener("focus", handleWindowFocus as EventListener);

    //   remove event listener when window is unfocused
    return () => {
      window.removeEventListener("focus", handleWindowFocus as EventListener);
    };
  }, []);

  const card = `**** **** **** ${asTemplateValue(defaultPaymentMethod?.properties?.lastFourDigits)}`;
  const expires = `${asTemplateValue(defaultPaymentMethod?.properties?.expirationMonth)}/${asTemplateValue(defaultPaymentMethod?.properties?.expirationYear)}`;
  const noPaymentMethod = !loading && !defaultPaymentMethod;

  return (
    <>
      <OverlayBackButton show fallbackUrl={PROFILE_URL} />
      <AppPageTitle title={translate("billingInformation")}></AppPageTitle>
      <nine-spacer s={"xl"}></nine-spacer>

      <AsyncContent check={[!loading]} height={"7em"}>
        {!noPaymentMethod && (
          <Data.Table
            title={translate("paymentMethod")}
            action={{ onAction: openStripe }}
          >
            <Data.Row title={translate("card")} data={!loading && card} />
            <Data.Row title={translate("expires")} data={!loading && expires} />
          </Data.Table>
        )}

        {noPaymentMethod && (
          <>
            <p style={{ marginTop: 0 }}>
              <Translate msg="payment.noMethods" />
            </p>
            <Collection>
              <CollectionItemLink
                icon={<IconCreditCardPaymentBilling />}
                label={translate("payment.addMethod")}
                onClick={openStripe}
              />
            </Collection>
          </>
        )}
      </AsyncContent>
    </>
  );
};

export default BillingInformation;
